.table-disqualified,
.table-disqualified > th,
.table-disqualified > td {
	color: gray;
	text-decoration: line-through;
}

.table tr:first-child th,
.table tr:first-child td {
	border-top: 0px;
}

.table tr:last-child th,
.table tr:last-child td {
	border-bottom: 1px solid #dee2e6;
}